import React from "react"
import styles from "./styles.module.less"
import { Link } from "gatsby"

import img from "./img/404.svg"
import alt from "./img/alt.png"

const ErrorPage = ({ useAlt = false }) => {
  let image = useAlt ? alt : img
  return (
    <div className={styles.ErrorPage}>
      <Link
        href="/404"
        className={styles.fourOhFour}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={image} alt="" />
        <p>
          Need to send a last-minute holiday card? <br />
          Use our 404 page to avoid the greeting card aisle <br />
          and get lost in a '90s time warp all at the same time.
        </p>
      </Link>
    </div>
  )
}

export default ErrorPage
