import React from "react"
import styles from "./styles.module.less"

const yuleLog =
  "https://ucarecdn.com/d4b37490-265f-4fb7-9b38-60d14f2c16bd/Fireplace_LoopwithFULLaudio_5min_720_compressed_3.mp4"
const karaoke =
  "https://ucarecdn.com/42b576de-a85d-4545-a63f-dad911b89309/Karaoke_YT480_v8_1.mp4"
const LFH =
  "https://ucarecdn.com/386e7ced-ebb1-48f1-938b-650c2cc708be/CopyofLFH16x9V02.mp4"
const BLM =
  "https://ucarecdn.com/13171640-03d3-422d-9a70-3859222407c7/CopyofWIDE_1920x1080.mp4"

import singalingPoster from "./images/ParodySong_Poster.jpg"
import yulePoster from "./images/YuleLog_Poster.jpg"

import LFHPoster from "./images/lfh.png"
import BLMPoster from "./images/blm.png"

const Video = ({ src = yuleLog, poster = yulePoster, children }) => {
  return (
    <div className={styles.Video}>
      {/* <div className={styles.Frame}></div> */}
      <video
        className={styles.Media}
        id="vid"
        loop
        autobuffer
        controls
        poster={poster}
      >
        <source type="video/mp4" src={src}></source>
      </video>
      <p className={styles.Caption}>{children}</p>
    </div>
  )
}

export const YuleLog = () => (
  <Video poster={yulePoster}>
    When the world is feeling a little cold,
    <br />
    come, warm by our digital Yule log.
  </Video>
)

export const Singalong = () => (
  <Video src={karaoke} poster={singalingPoster}>
    Digital meetings are tired. <br />
    Karaoke bars are closed.
    <br /> And we can't seem to get over either. <br />
    Cue the sweet singalong catharsis!
  </Video>
)
export const Lfh = () => (
  <Video src={LFH} poster={LFHPoster}>
    In the earliest days of uncertainty, we challenged Staff <br /> to control
    what we could control—and Lead. From. Home.
  </Video>
)

export const Blm = () => (
  <Video src={BLM} poster={BLMPoster}>
    Perhaps the most critical work of the year, we
    <br />
    pledged to get our own house right. Because
    <br />
    Black Lives Matter, always.
  </Video>
)

export default Video
