import React from "react"

import styles from "./styles.module.less"
import arrow from "./images/arrow.svg"

const Cta = ({ hide }) => {
  const goTo = e => {
    var el = document.getElementById("gift")
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    })
    e.preventDefault()
  }

  return (
    <a
      className={styles.Cta}
      onClick={goTo}
      rel="noopener noreferrer"
      style={{ display: hide ? "none" : "block" }}
    >
      <button className={styles.button} onClick="">
        <img src={arrow} alt="" />
      </button>
    </a>
  )
}

export default Cta
