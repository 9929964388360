import React from "react"
import styles from "./styles.module.less"

import logo from "./img/thesis_logo_black.png"
import star from "./img/star.svg"
import starSm from "./img/starSmMb.svg"
import starLg from "./img/starLgMb.svg"
import starLg1 from "./img/starLgMb1.png"
import starLg2 from "./img/starLgMb2.png"
import line from "./img/line.svg"

const Header = props => {
  return (
    <div>
      <div className={styles.HeaderMobile}>
        <img className={styles.star} src={starLg2} alt="" />
        <div className={styles.info}>
          <div>
            <h1 className={styles.clientName}>
              <a href="/" target="_blank">
                <img className={styles.logo} src={logo} alt="Thesis" />
              </a>{" "}
            </h1>
          </div>
          <div className={styles.line}></div>

          <div className={styles.dates}>
            <h2 className={styles.date}>January 2021</h2>
            <img className={styles.star} src={starSm} alt="" />
            <h2 className={styles.date}>AKA not 2020</h2>
          </div>
        </div>
        <img className={styles.star} src={starLg1} alt="" />
      </div>
      <div className={styles.HeaderDesktop}>
        <h2 className={styles.date}>January 2021</h2>

        <h1 className={styles.clientName}>
          <img className={styles.star} src={star} alt="" />
          <div>
            <a href="/" target="_blank">
              <img className={styles.logo} src={logo} alt="Thesis" />
            </a>{" "}
          </div>
          <img className={styles.star} src={star} alt="" />
        </h1>
        <h2 className={styles.date}>AKA not 2020</h2>
      </div>
    </div>
  )
}

export default Header
