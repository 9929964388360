import React from "react"
import styles from "./styles.module.less"

import arrow from "./img/arrowWh.svg"

const Intro = props => {
  return (
    <div className={styles.Intro}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>
            We made it! <br />
            (together)
          </h1>
        </div>
        <div className={styles.paragraph}>
          <p>
            Through thick and thin, fear and doubt.{" "}
            <br className={styles.mobileHide} />
            Through civil unrest and smoke all about.{" "}
            <br className={styles.mobileHide} />
            Through barking dogs and cat butts on keyboards.{" "}
            <br className={styles.mobileHide} />
            Through “sorry, I was muted,” and oh, so much more. <br />
            <br />
            Good team, we stuck it out. <br className={styles.mobileHide} />
            We came together from afar <br className={styles.mobileHide} />
            to create such great (at an impressive rate),{" "}
            <br className={styles.mobileHide} />
            and for 2021, set the bar.
            <br />
            <br />
            ###
            <br />
            <br />
            Scroll to see how we did alright for ourselves in 2020.
          </p>
        </div>

        {/* <button className={styles.button} onClick="">
          <img src={arrow} alt="" />
        </button> */}
      </div>
    </div>
  )
}

export default Intro
