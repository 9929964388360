import React from "react"
import styles from "./styles.module.less"

const SimpleParagraph = props => {
  return (
    <div className={styles.header}>
      <h1>{props.headline}</h1>
      <p>{props.par}</p>
    </div>
  )
}

export default SimpleParagraph
