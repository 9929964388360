import React from "react"

import spotify from "./../images/spotify.png"

import styles from "./styles.module.less"

const Music = () => {
  return (
    <a
      href="https://open.spotify.com/playlist/6ONcaV2NTZFTLmyekEI8kv"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className={styles.Music} src={spotify} alt="" style={{}} />
    </a>
  )
}

export default Music
