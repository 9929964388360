import React, { useRef, useEffect, useState } from "react"

import Outro from "../Outro"
import Footer from "../Footer"
import CtaArrow from "../CtaArrow"

const Closing = props => {
  const ref = useRef(null)
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    if (!ref.current) return
    const o = new IntersectionObserver(entries => {
      if (entries[0].intersectionRatio <= 0) {
        setHidden(false)
        return
      }
      setHidden(true)
    })
    o.observe(ref.current)
    return () => o.unobserve(ref.current)
  })

  return (
    <div ref={ref}>
      <CtaArrow hide={hidden} />
      <Outro img={props.outroImg} copy={props.outroCopy} />
      <Footer />
    </div>
  )
}
export default Closing
