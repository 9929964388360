import React from "react"

import styles from "./styles.module.less"

const Footer = props => {
  return (
    <div className={styles.Footer}>
      <div className={styles.col}>
        <h2>Address</h2>
        <address>
          1417 NW Everett Street
          <br />
          Suite 300
          <br />
          Portland, OR 97209
        </address>
      </div>
      <div className={styles.col}>
        <h2>Call us</h2>
        <a href="tel:503-221-6200">(503) 221-6200</a>
      </div>
      <div className={styles.col}>
        <h2>Social</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/thesis.agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/thesis-agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a
              href="http://facebook.com/thesis.agency"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/Thesis_Agency"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
