import React from "react"
import styles from "./styles.module.less"
import logo from "./img/logo.svg"
import arrow from "./img/arrowWh.svg"
const Outro = props => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <div id="gift" className={styles.Outro}>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.header}>
            <h1>
              Cue triumphant music! <br />
              Pop that e-bubbly!
              <br />
              <br />
              🥂
            </h1>
          </div>
          <div className={styles.paragraph}>
            <p>
              Because there’s a whole lot to be proud of.
              <br className={styles.mobileHide} /> And what doesn’t kill us
              makes us more resilient,
              <br className={styles.mobileHide} /> more creative, and more
              equipped with the on-camera skills
              <br className={styles.mobileHide} /> needed to start our own
              YouTube channels.
              <br />
              <br />
              We made it through 2020. <br /> And we're gonna keep making it.
            </p>
          </div>
        </div>
        <div></div>
        <div className={styles.outro}>
          <span>
            {" "}
            Much love,
            <br />
          </span>
          <a href="/" target="_blank">
            <img className={styles.logo} src={logo} alt="thesis" />
          </a>
          <button className={styles.button1} onClick={scrollTop}>
            <img src={arrow} alt="" />
          </button>
        </div>
        {/* <button className={styles.button}>
          Check out your gift <img src={arrow} alt="" />
        </button> */}
      </div>
    </div>
  )
}

export default Outro
